import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../components/component-style/index.css'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import useWindowSize from '../utils/useWindowSize'

const IndexPage = ({ data }) => {
    const windowWidth = useWindowSize()
    const [showImg, setShowImg] = useState(true)

    useEffect(() => {
        setShowImg(windowWidth > 1199)
    }, [windowWidth])
    return (
        <Layout>
            <SEO title="Home" />
            <div className="indexContainer">
                <div className="blueBlock leftBlock">
                    <div className="leftBlockText">
                        <h2>Hi, I'm Tom!</h2>
                        <p>
                            I'm a software developer and photographer based in
                            San Jose, California and working on a Creative
                            Cloud team at Adobe. I really enjoy learning by
                            working on new projects. You can check out some
                            personal projects{' '}
                            <Link to="/projects" className="link">
                                here
                            </Link>
                            !
                        </p>
                    </div>
                    {showImg && (
                        <div className="imgCont">
                            <GatsbyImage
                                image={data.tom.childImageSharp.gatsbyImageData}
                                alt="Tom Profile"
                                className="blockImgLeft" />
                        </div>
                    )}
                </div>
                <div className="redBlock rightBlock">
                    {showImg && (
                        <div className="imgCont">
                            <GatsbyImage
                                image={data.ihack.childImageSharp.gatsbyImageData}
                                alt="Project Cover"
                                className="blockImgRight" />
                        </div>
                    )}
                    <div className="rightBlockText">
                        <Link to="/blog/2020-04-02-ihack">
                            <h2 className="link">Recent Project - iHack G4</h2>
                            <p className="bodytext">
                                The 2002 iMac G4 "Sunflower" was a machine with
                                an incredibly unique design that didn't get in
                                the way of its functionality.
                            </p>
                            <p className="bodytext">
                                Why not give it new life?
                            </p>
                        </Link>
                    </div>
                </div>
                <div className="greenBlock leftBlock">
                    <div className="leftBlockText">
                        <Link to="/photography">
                            <h2 className="link">Photo Portfolio</h2>
                            <p className="bodytext">
                                My photography portfolio contains portraiture,
                                landscapes, and product photography. I've been
                                a pet photographer for 6 years and
                                have worked with a variety of companies that
                                include Rover, Little Donkey Supply Co., Mint
                                Creative, Partridge and Pooch, and Lifeline
                                Animal Project.
                            </p>
                        </Link>
                    </div>
                    {showImg && (
                        <div className="imgCont">
                            <GatsbyImage
                                image={data.portfolio.childImageSharp.gatsbyImageData}
                                alt="Gallery Overview"
                                className="blockImgLeft" />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default IndexPage

export const query = graphql`{
  tom: file(relativePath: {regex: "/tom.png/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  ihack: file(relativePath: {regex: "/ihack.png/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  portfolio: file(relativePath: {regex: "/photo-grid.png/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`
